import React from 'react'
import './RoomOne.css'

// Components
import RoomGallery from './RoomGallery';

// Icons
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KitchenIcon from '@material-ui/icons/Kitchen';
import WifiIcon from '@material-ui/icons/Wifi';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';

function RoomOne() {

    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(76%20of%20150).jpg?alt=media&token=3a3908ee-66b1-4ac0-85e2-c649ef78e5b8',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(76%20of%20150).jpg?alt=media&token=3a3908ee-66b1-4ac0-85e2-c649ef78e5b8',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(131%20of%20150).jpg?alt=media&token=21d8c5cb-fa46-4015-8f50-9690f95ab78d',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(131%20of%20150).jpg?alt=media&token=21d8c5cb-fa46-4015-8f50-9690f95ab78d',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(130%20of%20150).jpg?alt=media&token=cbf62c9c-adc9-4e21-9bd6-b3501b40a3a8',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(130%20of%20150).jpg?alt=media&token=cbf62c9c-adc9-4e21-9bd6-b3501b40a3a8',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(128%20of%20150).jpg?alt=media&token=fea09dc6-23d9-4318-8541-1de89f9b3acb',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(128%20of%20150).jpg?alt=media&token=fea09dc6-23d9-4318-8541-1de89f9b3acb',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(126%20of%20150).jpg?alt=media&token=a2a4bc3e-deb3-4d15-ba09-aaf3fe5ba10c',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(126%20of%20150).jpg?alt=media&token=a2a4bc3e-deb3-4d15-ba09-aaf3fe5ba10c',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(125%20of%20150).jpg?alt=media&token=7e8cb07b-1c75-494c-8376-ac6d3e333f3e',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FDana%2FAnoud%20Manor%20(125%20of%20150).jpg?alt=media&token=7e8cb07b-1c75-494c-8376-ac6d3e333f3e',
        },
        
];

    return (
<div className='room-one'>
           <div className="room-one-left">
           <h1>Dana Cottage - R 2990 / night</h1> 
            <p>The Dana Cottage is perfect for that intimate getaway. Despite being the smallest of the rooms at Anoud Manor, The Dana cottage still boasts it's own kitchen & Lounge whilst opening up onto a patio alongside the pool area meaning that you have the perfect mixture of privacy and luxury. With easy access to the pool area, sauna and spa, this quaint cottage offers everythign you could need whilst exploring the Cape.</p>
            <br />
            {/* <p>And also the following:</p> */}
            <br />
            <div className="icon-group">
                <HotelIcon /><p>1 Bedroom</p>
            </div>
            <div className="icon-group">
                <BathtubIcon /> <p>1 En Suite Bathroom</p>
            </div>
            <div className="icon-group">
                <KitchenIcon /> <p>Private Kitchen</p>
            </div>
            <div className="icon-group">
                <WifiIcon /> <p>Free Wifi</p>
            </div>
            <div className="icon-group">
                <EmojiFoodBeverageIcon /><p>Private Lounge</p>
            </div>
            <br></br>
           </div>
           <div className="room-one-right">
           <div className="room-one-image-container">
                    {/* <img className='room-one-image' src='https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg' alt='room-one' /> */}
                    <RoomGallery imageArray={images} />
                </div>
          
           </div>
        </div>
    )
}


export default RoomOne

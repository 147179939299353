import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <div className='footer'>
            <div className="footer-left">
                {/* <h1>Hout Bay Manor House</h1> */}
                <img src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/ANOUD%20MANOR%20LOGO.png?alt=media&token=05fbcfce-77a4-41f0-b940-0fbb24bf269d' alt='headerimg' />

            </div>
            <div className="footer-middle">

            </div>
            <div className="footer-right">
                <h2>Contact Us</h2>
                <p>+ 27 21 791 0964</p>
                <p>+ 27 71 800 5872</p>
                <p>+ 96 59 966 2667 (Whatsapp)</p>
                {/* <p>info@anoudmanor.com</p> */}
                <p>41 Valley Rd, Hout Bay, Cape Town, 7806</p>
            </div>
        </div>
    )
}

export default Footer

import React from 'react'
import './ImageModal.css'


  

function ImageModal({ imageLink, handleClose }) {

    document.addEventListener('keyup', (evt) => {
        if (evt.key === 'Escape') {
            handleClose();
        }
    })

    return (
        <div onClick={handleClose} className='image-modal'>
            <div className="image-modal-content">
                {/* <h1>CHOSEN IMAGE NIGGGGGGE</h1> */}
                <img alt='gallery' src={imageLink} />
            </div>
        </div>
    )
}

export default ImageModal

import React from 'react'
import './RoomGallery.css'

import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'


function RoomGallery({ imageArray, }) {

    return (
        <div className='room-gallery'>
            <ImageGallery useBrowserFullScreen={true} items={imageArray} autoPlay={false} showThumbnails={true} showPlayButton={false} showFullscreenButton={true} />
        </div>
    )
}

export default RoomGallery

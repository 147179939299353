import React from 'react'
import './MainRoom.css'

// Components
import RoomGallery from './RoomGallery';

// Icons
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KitchenIcon from '@material-ui/icons/Kitchen';
import WifiIcon from '@material-ui/icons/Wifi';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';

function MainRoom() {

    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(104%20of%20150).jpg?alt=media&token=41ce9dcb-8466-48d5-ad4c-8a5bedd536b4',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(104%20of%20150).jpg?alt=media&token=41ce9dcb-8466-48d5-ad4c-8a5bedd536b4',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(115%20of%20150).jpg?alt=media&token=a1ecdd75-9988-4576-af3e-56b67cd8fa2c',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(115%20of%20150).jpg?alt=media&token=a1ecdd75-9988-4576-af3e-56b67cd8fa2c',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(33%20of%20150).jpg?alt=media&token=351f44c0-2854-4111-859e-41c38f951494',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(33%20of%20150).jpg?alt=media&token=351f44c0-2854-4111-859e-41c38f951494',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(52%20of%20150).jpg?alt=media&token=4493de49-adf8-4b29-b92c-aff095b4f0f7',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(52%20of%20150).jpg?alt=media&token=4493de49-adf8-4b29-b92c-aff095b4f0f7',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(95%20of%20150).jpg?alt=media&token=fba4f8fa-9349-4762-a4a5-07513c97e3ac',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(95%20of%20150).jpg?alt=media&token=fba4f8fa-9349-4762-a4a5-07513c97e3ac',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(96%20of%20150).jpg?alt=media&token=84f33d42-b60e-4cd1-a15f-7edc01b341dc',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FMain%2FAnoud%20Manor%20(96%20of%20150).jpg?alt=media&token=84f33d42-b60e-4cd1-a15f-7edc01b341dc',
        },


];

    return (
        <div className='main-room'>
           <div className="main-room-left">
                <div className="main-room-image-container">
                    {/* <img className='main-room-image' src='https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg' alt='main-room' /> */}
                    <RoomGallery imageArray={images} />
                </div>
           </div>
           <div className="main-room-right">
           <h1>Main House - R 7990 / night</h1> 
            <p>The Main House at Anour Manor is the place for your whole group. Wether you're travelling with family or a group of friends the main house provides both privacy with each bedroom hosting it's own en suire bathroom as well as common areas just for you and your party. Set in the orignal house & equipped with your own full scale kitchen, our main house has all the bits and pieces that you need to make our home our own for your stay.</p>
            <br />
            {/* <p>And also the following:</p> */}
            <br />
            <div className="icon-group">
                <HotelIcon /> <p>4 Bedrooms</p>
            </div>
            <div className="icon-group">
                <BathtubIcon /> <p>5 Bathrooms</p>
            </div>
            <div className="icon-group">
                <KitchenIcon /> <p>Fully equipped kitchen</p>
            </div>
            <div className="icon-group">
                <WifiIcon /> <p>Free Wifi</p>
            </div>
            <div className="icon-group">
                <EmojiFoodBeverageIcon /><p>Private Lounge & Patio</p>
            </div>
            <br></br>
           </div>
        </div>
    )
}

export default MainRoom

import React, { useState, useRef } from 'react'
import './Contact.css'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DirectionsBox from './DirectionsBox';

import { CSSTransition } from 'react-transition-group';

import { db } from '../../firebase';

import LoadingOverlay from 'react-loading-overlay';

function Contact() {

    // State
    const [errorMessage, setErrorMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [loading, setLoading] = useState(false)
    // Refs
    const refOne = useRef();
    const refTwo = useRef();
    const refThree = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const textAreaRef = useRef();

    // Validate Input
    function validateInput() {
        setErrorMessage('')

        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const text = textAreaRef.current.value;
        
        // Name
        if (name === '') {
            setErrorMessage('Please enter a name')
            return false;
        }

        // Email
        if (email === '') {
            setErrorMessage('Please enter an email address');
            return false;
        }

        // Textarea
        if (text.length < 30) {
            setErrorMessage('At least 30 characters required')
            return false;
        }

        setMessageSent(false);
    }

    // Store text message
    async function storeMessage() {
        setLoading(true)
        const validate = validateInput();
        const mainChecked = refOne.current.checked;
        const danaChecked = refTwo.current.checked;
        const noorChecked = refThree.current.checked;
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const text = textAreaRef.current.value;        
        if (validate === false) {
            return setLoading(false)
        } 

        // Store in firebase
        try {
            await db.collection('queries').add({
                mainChecked,
                danaChecked,
                noorChecked,
                name,
                email,
                text
            });
            setLoading(false)
            setMessageSent(true)

        } catch (err) {
            setLoading(false)

            setErrorMessage('Sorry, we could not store your message - please try again');
        }
    }


    return (
        <div className=''>
                        
                {/* <div className="contact-header">
                    <h1>CONTACT US</h1>
                </div> */}

            <CSSTransition in={true} appear={true} timeout={1200} classNames='fade' onEnter={() => console.log('enter')} onExit={() => alert('exit')} >
            <LoadingOverlay
  active={loading}
  spinner
  text='Sending your message'
  >
<div className="">
<div className='contact'>
                            <img className='header-img' src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FAnoud%20Manor%20(65%20of%20150).jpg?alt=media&token=5bb6a3d6-9c8b-43d3-9ce4-63c1ed0f5e7e' />
                <div className="contact-blurb">
                    <p>Coming to Cape Town? Looking to find out more about us? We would love to hear from you. Whether you would like to enquire about a room's availability or just find out some more info about our beautiful Manor House and it's surrounds, please use the form below to leave us a message and we will be sure to get back to you as soon as we possibly can.  Our direct contact details are as follows:</p>
                </div>
                <div className="contact-details-container">
                <p>+ 27 21 791 0964 (Landline)</p>
                <p>+ 27 71 800 5872 (Mobile)</p>
                <p>+ 96 59 966 2667 (Whatsapp) </p>
                </div>

                <div className="hr-container">
                <hr />

                </div>
                <div className="contact-container">
                    {messageSent ?
                    <div className="contact-left">
                        <h2>Thanks for submitting!</h2>
                        <p>We wil be in touch shortly</p>
                    </div>
                    : <div className="contact-left">
                        <div className="interest-row">
                            <p>I am interested in:</p>
                        </div>
                        <div className="checkbox-row">
                        <FormControlLabel value='end' control={                        <Checkbox
        inputRef={refOne}
        onChange={() => console.log(refOne.current.checked)}
        defaultChecked={false}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />} label='Main Room' labelPlacement='end' />
                        <FormControlLabel value='end' control={                        <Checkbox
        inputRef={refTwo}
        defaultChecked={false}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />} label='Dana Room' labelPlacement='end' />
                        <FormControlLabel value='end' control={                        <Checkbox
        inputRef={refThree}
        defaultChecked={false}
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />} label='Noor Room' labelPlacement='end' />
                        </div>
                        <input ref={nameRef} type='text' placeholder='Name' />
                        <input ref={emailRef} type='email' placeholder='Email Address' />
                        <textarea ref={textAreaRef} placeholder={'Tell us what you need'} rows={9} />
                        {errorMessage !== '' && <p className='error-message'>{errorMessage}</p>}
                        <div className="contact-button-row">
                            <button onClick={() => storeMessage()}>Send</button>
                        </div>
                    </div>}
                <div className="contact-right">
                    <iframe title='map-frame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.8899539985055!2d18.355587415214305!3d-34.02103558061454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc68e83fbc2d37%3A0xe0c57737eba103a0!2s41%20Valley%20Rd%2C%20Hout%20Bay%2C%20Cape%20Town%2C%207806!5e0!3m2!1sen!2sza!4v1633328508491!5m2!1sen!2sza"  allowFullScreen={true} loading="lazy"></iframe>
                </div>
                </div>
                
                <div className="contact-dir-boxes">
                    <div className="dir-boxes-row">
                    <DirectionsBox 
                    link={'https://www.google.com/maps/dir/Cape+Town+International+Airport,+Matroosfontein,+Cape+Town/arnoud+manor/@-33.9688306,18.4028956,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x1dcc4542f7400bbd:0x40487579e3cf5e90!2m2!1d18.6020851!2d-33.971463!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184'}
                    from={'The Airport'}
                    description={"From Cape Town International Aiport, take the N2 towards Cape Town for 10.2 km's. Take exit 6 for M3/Rhodes Drive toward Observatory/Muizenberg and continue for 4 km. Turn right onto Rhodes Ave/M63 and continue for 2.5 km then Turn right onto Rhodes Dr/M63 for 4.6 km. At the roundabout, take the 2nd exit onto Hout Bay Main Rd/M63 for 2.7 km before turning into Disa River Road, then right into Valley Road and continue for 2.1km where you will find Anoud Manor on your left."} />
                
                    <DirectionsBox 
                    link={'https://www.google.com/maps/dir/Waterfront,+Victoria+%26+Alfred+Waterfront,+Cape+Town/arnoud+manor/@-33.9688306,18.4028956,12z/data=!4m13!4m12!1m5!1m1!1s0x1dcc677f396ebf7d:0xdf797d2831d4f0bd!2m2!1d18.4216388!2d-33.903486!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184'}
                    from={'The Waterfront'}
                    description={"From the V&A Waterfront, follow signals for Seapoint/Bantry Bay/Camps Bay, taking either Beach Road (Granger Bay Boulevard), Helen Suzman Boulevard Strand Street/High Level Road. Continue following signs to Vicotrai Road (near camps bay beach). Take Victoria Road (M6) heading south towards Llandudno/Hour Bay for 10.8 km. Once you have descended Suikerbossie Hill, keep left at the traffic lights to stay on Victoria Road. At the roundabout, take the first exit (left) into Valley Road and continue for 1.2 km where you will find Anoud Manor on your right."} />
                
                    </div>
                    <div className="dir-boxes-row">

                    <DirectionsBox 
                    link={'https://www.google.com/maps/dir/Constantia+Village,+Cape+Town/arnoud+manor/@-33.9661899,18.335392,12z/data=!4m13!4m12!1m5!1m1!1s0x1dcc4262e67555cb:0x81db651f2ac2a09f!2m2!1d18.441618!2d-34.0219859!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184'}
                    from={'The Southern Suburbs'}
                    description={"From Constantia Village, take Constantia Main Road/M39 for 4.3 km up towards Constantia Neck. At the roundabout, continue straight onto Hout Bay Main Road/M63 for 2.7 km before turning into Disa River Road, then right into Valley Road and continue for 2.1km where you will find Anoud Manor on your left."} />
                
                    <DirectionsBox 
                    link={'https://www.google.com/maps/dir/Camps+Bay+Beach,+Cape+Town/arnoud+manor/@-33.9905065,18.3260322,13z/am=t/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x1dcc6653756d46b9:0x310788af50afb3fb!2m2!1d18.3776413!2d-33.9506605!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184'}
                    from={'Camps Bay'}
                    description={"From Camps Bay beach, take Victoria Road (M6) heading south towards Llandudno/Hour Bay for 10.8 km. Once you have descended Suikerbossie Hill, keep left at the traffic lights to stay on Victoria Road. At the roundabout, take the first exit (left) into Valley Road and continue for 1.2 km where you will find Anoud Manor on your right."} />
                
                    </div>

                </div>
                
                </div>
                </div>
                            </LoadingOverlay>
            </CSSTransition>
            {/* <button onClick={() => setShow(true)}>Click me</button> */}
        </div>
    )
}

export default Contact

import React, {useEffect, useRef, useState} from 'react'
import './ImageCarousel.css'

function ImageCarousel() {
    const images = [
        {
            original: 'https://www.hotelbusiness.com/wp-content/uploads/2018/03/crop-AdareManor.jpg',
            thumbnail: 'https://www.hotelbusiness.com/wp-content/uploads/2018/03/crop-AdareManor.jpg',
        },
        {
            original: 'https://kateandtoms.com/wp-content/uploads/2014/12/The-Manor-29.12.14-58-1600x900.jpg',
            thumbnail: 'https://kateandtoms.com/wp-content/uploads/2014/12/The-Manor-29.12.14-58-1600x900.jpg',
        },
        {
            original: 'https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg',
            thumbnail: 'https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg',
        },
    
];
    const [currentImage, setCurrentImage] = useState('https://kateandtoms.com/wp-content/uploads/2014/12/The-Manor-29.12.14-58-1600x900.jpg');
    const currentImageRef = useRef();
    const [opacity, setOpacity] = useState(1);
    // currentImageRef.current = currentImageRef

    useEffect(() => {
        setTimeout(changeImage, 5000);
        // changeImage();
    }, []);

    useEffect(() => {
        console.log(opacity)
    }, [opacity])

    async function fadeOut() {
        console.log(currentImageRef.current.style.opacity);
        let opacityVal = 1;
        for (var i = 0; i < 10; i++) {
            // setOpacity(current => current - 0.1);
            setOpacity(opacityVal);
            opacityVal -= 0.1;
            console.log(opacity)
            setTimeout(() => {}, 50);
        }
        console.log(currentImageRef.current.style.opacity);

    }
    async function fadeIn() {
        let opacityVal = 0;
        for (var i = 0; i < 9; i++) {
            currentImageRef.current.style.opacity = opacityVal;
            // setOpacity(current => current + 0.1);
            opacityVal += 0.1;
            setTimeout(fadeIn, 50)
        }
    }

    async function changeImage() {
        await fadeOut();
        // console.log(currentImageRef.current.src)
        const currentIndex = images.indexOf(currentImageRef.current.src);
        if (currentIndex === images.length - 1) {
            // console.log(images[0]);
            currentImageRef.current.src = images[0];
        } else {
            // console.log(images[currentIndex + 1])
            currentImageRef.current.src = images[currentIndex + 1];
            // console.log(currentImage);
        }
        await fadeIn();
        setTimeout(changeImage, 5000)
    }

    return (
        <div className='image-carousel'>
            <div className="image-container">
                <img className='carousel-image' style={{opacity:opacity}} ref={currentImageRef} alt='main-slide' src={'https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg'} />
            </div>
            <div style={{textAlign:'center'}}>
                <span className="dot"></span> 
                <span className="dot"></span> 
                <span className="dot"></span> 
            </div>
        </div>
    )
}

export default ImageCarousel

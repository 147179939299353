import React from 'react'
import './About.css'

import { CSSTransition } from 'react-transition-group';
import DirectionsBox from '../Contact/DirectionsBox';
import RoomBox from './RoomBox';
import NearbyBox from './NearbyBox';
 
function About() {
    return (
        <div className='about'>
            {/* <div className="about-header">
            <h1>ABOUT</h1>
            </div> */}
            <CSSTransition in={true} appear={true} timeout={1200} classNames='fade' onEnter={() => console.log('enter')} onExit={() => alert('exit')} >
            <div className="">
            <img className='header-img' src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FAnoud%20Manor%20(65%20of%20150).jpg?alt=media&token=5bb6a3d6-9c8b-43d3-9ce4-63c1ed0f5e7e' />

            <div className="about-one">
                <div className="about-one-left">
                <img alt='about-one' src={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(25%20of%20150).jpg?alt=media&token=988434e8-dd53-45b2-a20c-9873820bb337'} />

                </div>
                <div className="about-one-right">
                <h2>WHO ARE WE</h2>
                <p>Set in the heart of leafy Hout Bay, Anoud Manor offers both serenity and convenience. We pride ourselves in offering stays that take you away from the "ordinary" and place you in the the "extraordinary." 
No matter the nature of your stay, we offer a variety options to fit your unique needs.<br /> <br />
As a leader in the lodging sphere, we take pride in offering the best travel and leisure accommodation and services . Our team of friendly and helpful staff will always endeavour to ensure that the needs of our guests are always fulfilled. We are dedicated to meeting the needs of our guests to ensure that their stay is nothing short of perfect.
</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda nostrum unde modi blanditiis id reiciendis quisquam accusamus quaerat, doloribus quam quos ducimus ullam dolor cumque voluptas sed voluptatibus quidem. Animi!</p> */}
                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem quaerat asperiores quae sit minus maiores exercitationem sint sunt, ad reprehenderit eligendi recusandae, labore est qui explicabo ex veritatis ipsum odit?Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, et accusantium quos repudiandae temporibus vitae veniam, ipsam libero praesentium facere pariatur neque dolore nihil aut distinctio quas amet iure error?</p> */}
                </div>
            </div>
            <div className="about-two">
                <div className="about-two-left">
                <h2>WHERE ARE WE</h2>
                <p>With mountains surronding the bay to the North, East and West and the Southern Atlantic Ocean to the south, it is no wonder that Hout Bay has remained the secluded gem that it is. The bay itself provides a cultural experience unlike any other nearby from the still bustling harbour & yacht club and the surrounding restaurants overlooking the sheltered beach to the expose of local talent at the Hout Bay Market. Once you're done exploring the bay, the rest of the Cape's beauty awaits you via one of three stunning mountain drives.<br /><br /> Pop over Suikerbossie hill and onto the Atlantic coastal road past Llandudno Beach, Camps Bay, The Twelve Apostles and all the way into the Cape Town City scene. A drive over Constantia neck takes you to the heart of the Cape Winelands with the a number of the Cape's most famous wine farms calling the area home and welcoming you in to drink, eat and take in the views.<br /> <br />The most grand of all of Cape Town's mountain passes lies to the south where Chapman's Peak Drive is carved out of the mountainside and leads towards wide open sandy beaches in Noordhoek and onwards to the edge of South Africa at the Cape Point National Park.</p>

                </div>
                <div className="about-two-right">
                    <img alt='manor-history' src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2Ftim-johnson-J-yDj62KSxE-unsplash.jpg?alt=media&token=43e38b9c-7c4f-41fe-8a8d-795b7da63c92' />
                </div>
            </div>
            <div className="about-boxes-container">
                <div className="about-box-row">
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2Fb7ed0a126986bbf53df37d561367898e.jpg?alt=media&token=9db0e5ba-f610-46cb-ab3a-615fec8f2adf'} venueName={'Hout Bay Market'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Bay+Harbour+Market,+Harbour+Road,+Hout+Bay,+Cape+Town/@-34.0379997,18.3344388,14z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc693860b13c01:0x9464c75928c1cf49!2m2!1d18.3473006!2d-34.0551066!3e0'} details={'4.8 km (8 minute car ride)'} />
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2F4ad7d2f2-4d27-4fa0-bc6f-968495b8c863.jpeg?alt=media&token=b57901be-a65b-4fc9-b110-f69d7f1d8d85'} venueName={'World of Birds'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/World+of+Birds+Wildlife+Sanctuary+%26+Monkey+Park,+Valley+Road,+Hout+Bay,+Cape+Town/@-34.018927,18.3581328,17z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc68e952f08c57:0xe62dee2a2df9be1b!2m2!1d18.36196!2d-34.016799!3e0'} details={'550m (1 minute walk)'} />
                </div>
                <div className="about-box-row">
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2F1-8.jpg?alt=media&token=8dd13be3-6d37-46b4-a776-b592334d758f'} venueName={'Chapman\'s Peak'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Circe+Launches,+Hout+Bay,+Cape+Town/@-34.035432,18.3439107,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc693a442d34c1:0x397eb71c76c0c86b!2m2!1d18.34657!2d-34.0494!3e0'} details={'10.1km (17 minute car ride)'} />
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2FMain1a.jpg?alt=media&token=2e3a173f-df5f-4e9e-a6de-35ee6b3fbf0a'} venueName={'Mainstream Mall'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Mainstream+Mall,+Princess+Street,+Hout+Bay,+Cape+Town/@-34.0318548,18.3472543,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc69186ba1bc17:0x54415d64321acf8!2m2!1d18.3575232!2d-34.0427273!3e0'} details={'3.2 km (5 minute car ride)'} />
                </div>
                
                
            </div>
            <div className="about-boxes-container">
                <div className="about-box-row">
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2Fdownload%20(1).jfif?alt=media&token=0b86dc9b-8e4f-41c6-8ce2-140a37c0079f'} venueName={'Cape Point'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Cape+Point,+Cape+Peninsula,+Cape+Town/@-34.1829026,18.2793802,11z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc23a068374b13:0xe84af0dd2a7804a1!2m2!1d18.4967666!2d-34.3566871!3e0'} details={'52.3 km (1 hr 10 minute car ride)'} />
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2Fdownload%20(2).jfif?alt=media&token=6e3fef73-768f-4a3e-965f-43dd31475239'} venueName={'Table Mountain Cable Car'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Table+Mountain+Aerial+Cableway,+Tafelberg+Road,+Table+Mountain+(Nature+Reserve),+Cape+Town/@-33.9861775,18.3382972,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc67a2628ee075:0x6c2354544821969b!2m2!1d18.4028828!2d-33.9481779!3e0'} details={'16.6 km (21 minute car ride)'} />
                </div>
                <div className="about-box-row">
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2Fdownload%20(3).jfif?alt=media&token=77645a96-05fe-4793-afb2-8948233d9b3e'} venueName={'Kirstenbosch Gardens'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Kirstenbosch+National+Botanical+Garden,+Rhodes+Drive,+Newlands,+Cape+Town/@-34.0042457,18.3793216,14z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc42a862facc79:0x1b7c8ad971eaa566!2m2!1d18.432722!2d-33.9875011!3e0'} details={'10.5 km (14 minute car ride)'} />
                <NearbyBox imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Directions%2FLlandudno-Beach-2-1.jpg?alt=media&token=d8c2a6e5-ff9f-4297-b911-1caf287c32bd'} venueName={'Llandudno Beach'} link={'https://www.google.co.za/maps/dir/Anoud+Manor,+41+Valley+Rd,+Hout+Bay,+Cape+Town,+7806/Llandudno+Beach,+Llandudno,+Cape+Town/@-34.0181875,18.3408159,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x1dcc69519a6b5703:0x18d8fad7a11d5e93!2m2!1d18.3578637!2d-34.0210184!1m5!1m1!1s0x1dcc68b122b1a0a7:0x37ef4d7ef79645ed!2m2!1d18.3412094!2d-34.0081097!3e0'} details={'5.8 km (10 minute car ride)'} />
                </div>
                
               
            </div>
            {/* <div className="about-three">
                <div className="about-three-left">
                    <h2>What can I find nearby?</h2>
                    <div className="about-three-left-text-container">
                    <p>- This thing to go do</p>
                    <p>- This thing to go do</p>
                    <p>- This thing to go do</p>
                    <p>- This thing to go do</p>
                    <p>- This thing to go do</p>
                    <p>- This thing to go do</p>
                    </div>

                </div>
                <div className="about-three-right">
                    <h2>Travel Times</h2>
                    <div className="about-three-right-text-container">
                                            <p>Minutes and seconds</p>
                    <p>Minutes and seconds</p>
                    <p>Minutes and seconds</p>
                    <p>Minutes and seconds</p>
                    <p>Minutes and seconds</p>
                    </div>

                </div>
            </div> */}
            </div>
            </CSSTransition>
        </div>
    )
}

export default About

import React, { useState, useEffect, useRef } from 'react'
import './Home.css'
import 'react-image-gallery/styles/css/image-gallery.css'

import { CSSTransition } from 'react-transition-group'
import ImageCarousel from './ImageCarousel'
import ImageGallery from 'react-image-gallery'
import RoomBox from './About/RoomBox' 

function Home() {

    useEffect(() => {
        setInterval(() => changeImage(), 6000)
    }, [])

    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(15%20of%20150).jpg?alt=media&token=68716e1f-8e6e-4bad-82cf-87f6d7af1e90',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(15%20of%20150).jpg?alt=media&token=68716e1f-8e6e-4bad-82cf-87f6d7af1e90',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(23%20of%20150).jpg?alt=media&token=af6bacb5-97a4-4037-884c-e7037f6b158f',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(23%20of%20150).jpg?alt=media&token=af6bacb5-97a4-4037-884c-e7037f6b158f',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(26%20of%20150).jpg?alt=media&token=bd9d035e-89af-4577-8d51-ae3323169007',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(26%20of%20150).jpg?alt=media&token=bd9d035e-89af-4577-8d51-ae3323169007',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(29%20of%20150).jpg?alt=media&token=39016b9b-f981-412f-8993-e2d3cfc0c2ed',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(29%20of%20150).jpg?alt=media&token=39016b9b-f981-412f-8993-e2d3cfc0c2ed',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(4%20of%20150).jpg?alt=media&token=64d68756-3991-4c2e-a4c3-1b423e864c96',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(4%20of%20150).jpg?alt=media&token=64d68756-3991-4c2e-a4c3-1b423e864c96',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(6%20of%20150).jpg?alt=media&token=4a090b2b-4f49-4472-b057-14df7712ec02',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FSlider%2FAnoud%20Manor%20(6%20of%20150).jpg?alt=media&token=4a090b2b-4f49-4472-b057-14df7712ec02',
        },

];

    const staffImages = [
        'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FAnoud%20Manor%20STAFF.jpg?alt=media&token=cc8110fd-a725-42ad-9358-c2262efa5901',
        'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FAnoud%20Manor%20(1%20of%202)-2.jpg?alt=media&token=38a5f93f-21d6-44fd-8a9e-fe725e55550a',
        'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FAnoud%20Manor%20(2%20of%202)-2.jpg?alt=media&token=88a43b21-9abc-4ddb-a552-8b468dffd5a6'

    ]


    const [imgNum, setImgNum] = useState(0);
    const imgNumRef = useRef();
    const imageRef = useRef();
    imgNumRef.current = imgNum;

    async function changeImage() {
        imageRef.current.style.opacity = 0;
        console.log(imgNumRef.current);
        setTimeout(() => {
            let set = false;
            if (imgNumRef.current === 0 && set === false) {
                console.log('setting to 1')
                setImgNum(1);
                set = true;
            } 
            if (imgNumRef.current === 1 && set === false) {
                setImgNum(2)
                console.log('setting to 2')
                set = true;

            }
            if (imgNumRef.current === 2 && set === false) {
                console.log('setting to 0')
                setImgNum(0);
                set = true;

            }

            // console.log('setting opacity')
            imageRef.current.style.opacity = 1;

        }, 1000)




    }
  
    return (
        <div className='home'>
            {/* <div className="home-header">
                <h1>HOME</h1>
            </div> */}
            <CSSTransition in={true} appear={true} timeout={600} classNames='fade' onEnter={() => console.log('enter')} onExit={() => alert('exit')} >
        <div className="home-container">
            <div className="home-full-image">
                <img alt='home-full' src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Anoud%20Manor%2C%20Hout%20Bay%20MAIN%20IMAGE%202%20(1%20of%201)-2.jpg?alt=media&token=4e3f6b71-6034-4e46-b69a-5b68ad4a76d4' />
            </div>
        <div className="home-room-boxes">
            <div className="room-box-header">
                {/* <h2>Room header</h2> */}
                <p>Situated in the tranquil setting of Hout Bay, the Anoud Manor Guest House offers both serenity and convenience. With many of The Cape's most sought after attractions little more than a short trip away, ours is the perfect place to kick up your feet after days spent taking in the surrounding natural beauty our home is so famous for. </p>
                {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus reprehenderit iure, sequi, modi ex quod amet rem qui accusamus quas consequatur voluptatibus incidunt sapiente a asperiores, hic voluptatum quasi accusantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas maiores, quas porro quia iure unde eligendi magnam exercitationem consequatur amet optio consequuntur rem iusto labore non aliquid mollitia quis repellat.</p> */}
            </div>
            <div style={{backgroundColor: 'transparent'}} className="room-box-title">
            <h2>What we have to offer</h2>
<p>From our intimate cottages to the 4 Bedroom Main House, we have the place for you. Each room has it's own private kitchen, lounge and dining areas. Why not have a look at what our rooms have to offer?
</p>
            </div>
            <div className="room-box-container">
                <RoomBox flex={'0.3'} imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FRooms%2FAnoud%20Manor%20(94%20of%20150).jpg?alt=media&token=54fe0054-e362-4073-ad3e-f5c6cb218d0e'}
                price='7990'
                roomName='Main Room'
                />
                <RoomBox flex={'0.3'} imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FRooms%2FAnoud%20Manor%20(130%20of%20150).jpg?alt=media&token=179b3d85-e6f9-4471-9e40-19dd74190e4f'}
                price='2990'
                roomName='Dana Room'
                />
                <RoomBox flex={'0.3'} imageLink={'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FFront%20Page%2FRooms%2FAnoud%20Manor%20(136%20of%20150).jpg?alt=media&token=fba1678b-51b6-40f9-aa59-70b212b7d326'}
                price='3990'
                roomName='Noor Room'
                />
            </div>
        </div>
        {/* <div className="home-two">
            <div className="home-two-left">
                <h2>Hotel Staff</h2>
                <img alt='staff' src='https://www.hospitalitymarketplace.co.za/wp-content/uploads/2018/06/hospitality-jobs.jpg' />
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis maiores ipsam dolor? Sapiente quos voluptate animi enim, officiis eligendi nesciunt consectetur aliquid suscipit ratione necessitatibus magni omnis reprehenderit incidunt dignissimos!</p>
            </div>
            <div className="home-two-right">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13224.116030853422!2d18.3607898!3d-34.043127!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2486875caff21ce9!2sHout%20Bay%20Manor!5e0!3m2!1sen!2sza!4v1629554552973!5m2!1sen!2sza"  allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div> */}
                <div className="home-one">
            <div className="home-one-left">
                <div className="home-one-left-image-container">
                <img ref={imageRef} className='home-one-image' src={staffImages[imgNumRef.current]} alt='home-main' />
                </div>
            </div>
            <div className="home-one-right">
                {/* <h2>Arnoud Manor Bitch</h2> */}
                <p>We like to belive that our staff are the best in the business. Our small team of staff means that you will always have a reliable point of contact with us. No matter what you need, our friendly staff have the experience and expertise to help with whatever you might need. We are always on hand to ensure that your stay is as comfortable and relaxing as you could imagine it being. From the fine work our chef's do in preparing delicious meals to our grounds and house-keeping staff, we are here for you!<br /><br /> 

A passion for service and the service industry and a desire to meet guests needs are essential qualities that we look for in all of our staff to ensure that all of our guests receive nothing but the best in service excellence.<br /><br />Additionally, we have a security station that is manned around the clock to ensure the safety of our guests.</p>
            </div>
        </div>
        <div className="home-three">
        <ImageGallery showPlayButton={false} showFullscreenButton={false} showNav={false} showThumbnails={false} autoPlay={true} slideDuration={4000} items={images} />

        </div>

        </div>
        </CSSTransition>
        </div>
    )
}

export default Home

import React from 'react'
import './Roombox.css'

import { useHistory } from 'react-router-dom'

function RoomBox({imageLink, roomName, price, flex}) {

    const history = useHistory();

    function goToRooms() {
        history.push('accomodation')
    }

    return (
        <div style={{flex: flex}} className='room-box'>
            <div className="room-box-image-container">
            <img alt='room-img' src={imageLink} />
            </div>
            <div className="room-box-content">
            <h3>{roomName}</h3>
            <p>From R{price}</p>
            <div className="room-box-button-row">
            <button onClick={() => goToRooms()} >Read More</button>
            </div>

            </div>
        </div>
    )
}

export default RoomBox

import React from 'react'
import './Rooms.css'

// Components
import MainRoom from './MainRoom'
import RoomOne from './RoomOne'
import RoomTwo from './RoomTwo'

import { CSSTransition } from 'react-transition-group'


function Rooms() {
    return (
        <div className='rooms'>
            {/* <div className="rooms-header-row">
            <h1>ACCOMODATION</h1>
            </div> */}
            

            <CSSTransition in={true} appear={true} timeout={600} classNames='fade' onEnter={() => console.log('enter')} onExit={() => alert('exit')} >
            <div className="rooms-container">
                
            <MainRoom />
            <RoomOne />
            <RoomTwo />
            </div>
            </CSSTransition>
        </div>
    )
}

export default Rooms

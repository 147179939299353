import React, { useEffect, useRef } from 'react'
import './DropDownMenu.css'

import { NavLink } from 'react-router-dom'

function DropDownMenu({ closeMenu }) {

    // Refs
    const menuRef = useRef();

    useEffect(() => {
        console.log(menuRef.current.style)
        menuRef.current.style.height = '200px';
    }, []);

    return (
        <div className='dropdown-menu' ref={menuRef} onClick={closeMenu}>
            <NavLink activeClassName='header-navlink-active' className='header-navlink dropdown-navlink' exact to='/'>Home</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink dropdown-navlink' to='/accomodation'>Accomodation</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink dropdown-navlink' to='/about'>About</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink dropdown-navlink' to='/gallery'>Gallery</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink dropdown-navlink' to='/contact'>Contact</NavLink>
        </div>
    )
}

export default DropDownMenu

import firebase from 'firebase/compat/app'
import 'firebase/auth'
import 'firebase/compat/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyA99-v3EY-Il1GM14IJvh1xSRr4XXHIgIY",
    authDomain: "hout-bay-manor-house.firebaseapp.com",
    projectId: "hout-bay-manor-house",
    storageBucket: "hout-bay-manor-house.appspot.com",
    messagingSenderId: "207858040204",
    appId: "1:207858040204:web:62ee469380bbc3db936aa9"
  });

export const admin = firebase;
export const db = app.firestore();
export default app
import React from 'react'
import './DirectionsBox.css'

function DirectionsBox({ from, link, description }) {
    return (
        <div style={{ backgroundImage: 'https://www.capi.com/wp-content/uploads/2019/03/thumbnail_Cape-Town-International-Airport-1.jpg' }} className='directions-box'>
            <div className="directions-box-left">
                <h3>
                    From {from}
                </h3>
                <p>
                    {description}
                </p>
            </div>
            <div className="directions-box-right">
                <button onClick={() => window.open(link, '_blank')}>Maps</button>
            </div>
        </div>
    )
}

export default DirectionsBox

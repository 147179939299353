import React, {useState, useRef} from 'react'
import './Gallery.css'

// Components
import ImageModal from './ImageModal';
import { CSSTransition } from 'react-transition-group'

function Gallery() {

    // State
    const [modalOpen, setModalOpen] = useState(false);
    const [chosenImage, setChosenImage] = useState('');
    const [activeTab, setActiveTab] = useState('general');

    // Ref
    const imageClickRef = useRef();

    function handleClick(event) {
        console.log(event.target.src);
        setChosenImage(event.target.src);
        setModalOpen(true);
    }
    

    return (
        <div className='gallery'>
            {modalOpen && <ImageModal handleClose={() => setModalOpen(false)} imageLink={chosenImage} />}
            {/* <div className="gallery-header-container">
            <h1>GALLERY</h1>

            </div> */}
            <CSSTransition in={true} appear={true} timeout={1200} classNames='fade' onEnter={() => console.log('enter')} onExit={() => alert('exit')} >

            <div className="gallery-container">
                
            <div className="image-tabs">
                <p id='general-tab' className={activeTab === 'general' ? 'active-image-tab' : ''} onClick={() => setActiveTab('general')} >General</p>
                <p>·</p>
                <p className={activeTab === 'main' ? 'active-image-tab' : ''} onClick={() => setActiveTab('main')}>Main House</p>
                <p>·</p>
                <p className={activeTab === 'dana' ? 'active-image-tab' : ''} onClick={() => setActiveTab('dana')}>Dana Cottage</p>
                <p>·</p>
                <p className={activeTab === 'noor' ? 'active-image-tab' : ''} onClick={() => setActiveTab('noor')}>Noor Cottage</p>
                <p>·</p>
                <p id='facilities-tab' className={activeTab === 'facilities' ? 'active-image-tab' : ''} onClick={() => setActiveTab('facilities')}>Amenities</p>
            </div>
            {activeTab === 'general' && <div className="image-grid-container">
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(10%20of%20150).jpg?alt=media&token=e89ba016-076c-4904-a4ec-268b35080323'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(11%20of%20150).jpg?alt=media&token=8dc97ddf-12ff-435c-bcbb-5d62faae52c3'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(13%20of%20150).jpg?alt=media&token=6bc8d681-e792-4cdd-bdbc-9d85fefd85ac'/>
                    </div>
                    <div className="grid-item flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(14%20of%20150).jpg?alt=media&token=bb710576-d27a-4969-b60d-2da5761966bf'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(15%20of%20150).jpg?alt=media&token=cace1ee9-0030-4aae-bcd4-c358261c6371'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(17%20of%20150).jpg?alt=media&token=7a28d930-d51f-4972-bfbb-27f181a02170'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(2%20of%20150).jpg?alt=media&token=2bd7550c-f347-4a12-b7c3-201912d6209a'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(20%20of%20150).jpg?alt=media&token=6cb50182-6e00-45fc-b51e-7f2a8a73d184'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(4%20of%20150).jpg?alt=media&token=91afd61c-b660-4aa4-92d2-8e711ebc66f1'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(5%20of%20150).jpg?alt=media&token=a47aabe9-093d-4463-afd1-343c57862a29'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(6%20of%20150).jpg?alt=media&token=bea4c9c8-1b9d-4ff1-80bb-bb898f1726e0'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FGeneral%2FAnoud%20Manor%20(9%20of%20150).jpg?alt=media&token=33156114-7501-4df2-bb89-d669bd697379'/>
                    </div>
                </div>
            </div>}
            {activeTab === 'main' && <div className="image-grid-container">
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(99%20of%20150).jpg?alt=media&token=49f18169-5162-46e8-8a64-7bbd95d4e850'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(97%20of%20150).jpg?alt=media&token=b54e37f5-d091-4541-a7a9-82b8ecf3c9b5'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(96%20of%20150).jpg?alt=media&token=52368ebd-7547-4806-b977-96c49cf2a6b4'/>
                    </div>
                    <div className="grid-item flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(94%20of%20150).jpg?alt=media&token=3eff7e63-c568-4283-8cbe-2226f8da987f'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(72%20of%20150).jpg?alt=media&token=087e39ea-3865-47b8-abc9-1e6deee7c13d'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(71%20of%20150).jpg?alt=media&token=e1af49ae-6073-4e38-9b39-182476b18090'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(70%20of%20150).jpg?alt=media&token=a6d00e44-19dd-4ea9-9905-ea86fced7d8b'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(62%20of%20150).jpg?alt=media&token=cb66da3d-3e23-435a-8739-f99a4d07d5ab'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(58%20of%20150).jpg?alt=media&token=300bebfc-150d-445f-ae31-38e525e350fe'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(57%20of%20150).jpg?alt=media&token=772ee756-3dc7-43fb-9de7-2f993ac046ca'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(52%20of%20150).jpg?alt=media&token=ba0a0fe6-88fe-4411-9fd2-e2a7552d302c'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(50%20of%20150).jpg?alt=media&token=13c8512e-e021-42a8-90cd-463d312cccbe'/>
                    </div>
                </div>
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(41%20of%20150).jpg?alt=media&token=47aeb505-30d5-49b5-a3ac-3af10722b248'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(34%20of%20150).jpg?alt=media&token=c435b2e4-c918-4495-948d-3c3d804da841'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(33%20of%20150).jpg?alt=media&token=5b7a2014-50c9-4e58-8797-f5355741ab81'/>
                    </div>
                    <div className="grid-item flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(32%20of%20150).jpg?alt=media&token=a5c9dc28-42ea-471e-b0ba-52bbc0ab7b3e'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(122%20of%20150).jpg?alt=media&token=b656deef-9115-47de-8dc6-2a345d586138'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(120%20of%20150).jpg?alt=media&token=238961f3-5175-4e1f-b8ac-15a6fd4ca486'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(119%20of%20150).jpg?alt=media&token=9fa48829-a2b0-4f7a-a50d-cf606796dc29'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(117%20of%20150).jpg?alt=media&token=b4c18d79-bab9-466a-8aeb-c8b296161579'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(115%20of%20150).jpg?alt=media&token=af4854e7-013e-491b-a88b-4790d574d1b5'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(114%20of%20150).jpg?alt=media&token=edcc8b95-7d1d-4c23-a03a-ee251bd98618'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(111%20of%20150).jpg?alt=media&token=de9708b6-800b-4974-9664-ce119ee38c16'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(110%20of%20150).jpg?alt=media&token=f0255024-6016-4b57-9f80-3bf92598f8ff'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(105%20of%20150).jpg?alt=media&token=81f49757-6988-4f7f-96b9-72b05aa5fc31'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(104%20of%20150).jpg?alt=media&token=eaf0d14e-4716-4ca2-908a-104dd3891775'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(103%20of%20150).jpg?alt=media&token=dc83aba6-bf99-4741-ae72-a2dc4f76872f'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FMain%20Room%2FAnoud%20Manor%20(102%20of%20150).jpg?alt=media&token=7a6c6d18-89d9-4d8a-bdd9-e6c7b21cb39d'/>
                    </div>
                </div>
            </div>}
            {activeTab === 'dana' && <div className="image-grid-container">
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(125%20of%20150).jpg?alt=media&token=e788cc04-d442-48d7-b51e-0f8c23c3bae0'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(126%20of%20150).jpg?alt=media&token=9dea5442-b642-4e12-b1ce-16068d158a36'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(127%20of%20150).jpg?alt=media&token=33b16304-ac36-4e84-973d-3d29b919aaea'/>
                    </div>
                    <div className="grid-item flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(128%20of%20150).jpg?alt=media&token=f6cf7135-4599-4b49-b8b0-91b2df0045a8'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(129%20of%20150).jpg?alt=media&token=22652853-6c3f-47b8-8296-a9b800021e0f'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(130%20of%20150).jpg?alt=media&token=6237716b-e488-438d-a0e1-0212ad4eb523'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(131%20of%20150).jpg?alt=media&token=b95c609b-7458-42f7-9197-d7916de6c495'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(132%20of%20150).jpg?alt=media&token=1eea6c63-a7ad-4fd8-9367-6489f997b52f'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(74%20of%20150).jpg?alt=media&token=250001f3-fd13-4bec-9add-8a6b8f5f96a3'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(75%20of%20150).jpg?alt=media&token=186617c8-9fa2-4ecb-96b5-263e978f6381'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(76%20of%20150).jpg?alt=media&token=d6e3ed3d-f01f-4bbf-9c9c-6a800e010f4b'/>
                    </div>
                    <div className="grid-item flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FDana%2FAnoud%20Manor%20(77%20of%20150).jpg?alt=media&token=f13f071d-4781-4b06-9a91-b31f7737a163'/>
                    </div>
                </div>
            </div>}
            {activeTab === 'noor' && <div className="image-grid-container">
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(88%20of%20150).jpg?alt=media&token=aecc6b10-c68f-4fec-8a54-8659bccbbd47'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(86%20of%20150).jpg?alt=media&token=936061a8-d702-4d6c-a55d-99961536c648'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(85%20of%20150).jpg?alt=media&token=7722447f-cd7a-4621-9a30-eb9559f92e94'/>
                    </div>
                    <div className="grid-item flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(84%20of%20150).jpg?alt=media&token=3322677d-3d28-40cf-8137-4e03c6d5981a'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(79%20of%20150).jpg?alt=media&token=36e669ac-1f53-4e2b-b36c-c09896797495'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(78%20of%20150).jpg?alt=media&token=8e2de0dd-8f41-47ab-ae34-fdead149880f'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(73%20of%20150).jpg?alt=media&token=7aac4a54-c4a2-492c-b58a-bdce3c2d97b0'/>
                    </div>
                    <div className="grid-item flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(145%20of%20150).jpg?alt=media&token=b0fa65de-a775-494b-bbc8-a5b465f31881'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(144%20of%20150).jpg?alt=media&token=a61473f4-ca84-4b6e-bd85-a3eae1da1192'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(143%20of%20150).jpg?alt=media&token=9e806689-b78c-4305-96cd-8fe4e0db7bad'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(142%20of%20150).jpg?alt=media&token=f254d278-47aa-4f11-b8fa-945437c52a83'/>
                    </div>
                    <div className="grid-item flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(141%20of%20150).jpg?alt=media&token=c7c928a3-4cb1-4049-bb16-445012d04944'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(140%20of%20150).jpg?alt=media&token=14c406b1-f920-4f6b-942c-73be57d22f52'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(137%20of%20150).jpg?alt=media&token=5ec36e8b-566d-483d-9336-ba55766ab342'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(136%20of%20150).jpg?alt=media&token=ee5e4ab7-2e2d-49c6-8b27-6331253af174'/>
                    </div>
                    <div className="grid-item flex-20">
                    <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FNoor%2FAnoud%20Manor%20(135%20of%20150).jpg?alt=media&token=c9d14063-8907-4ebb-b340-7e01a383e776'/>
                    </div>
                </div>
            </div>}
            {activeTab === 'facilities' && <div className="image-grid-container">
                <div className="grid-row height-30">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(91%20of%20150).jpg?alt=media&token=ed31cdde-752a-49a8-a6d9-aa5448472682'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(90%20of%20150).jpg?alt=media&token=0d1da73f-8bda-4d95-89e4-7d7b442edf2f'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(89%20of%20150).jpg?alt=media&token=c76f594b-588d-433b-97c5-8fce7001a17c'/>
                    </div>
                    <div className="grid-item flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(150%20of%20150).jpg?alt=media&token=0cb9e2ef-a6c0-4803-9768-ddba91a2ca60'/>
                    </div>
                </div>
                <div className="grid-row height-50 mt-5">
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(148%20of%20150).jpg?alt=media&token=5bf8af73-a67b-44cb-a5d4-c8f85f17397c'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(149%20of%20150).jpg?alt=media&token=1c738a0c-ef3e-4552-bafb-80e8454c9f63'/>
                    </div>
                    <div className="grid-item mr-5 flex-30">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(23%20of%20150).jpg?alt=media&token=bb04b1b2-8fe0-4ca9-ace7-fd31ae272160'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(24%20of%20150).jpg?alt=media&token=f6d0a21b-496c-4934-8344-7da365631a92'/>
                    </div>
                </div>
                <div className="grid-row height-40 mt-5">
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(25%20of%20150).jpg?alt=media&token=988434e8-dd53-45b2-a20c-9873820bb337'/>
                    </div>
                    <div className="grid-item mr-5 flex-40">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(26%20of%20150).jpg?alt=media&token=a7fa4ede-d861-438a-8776-728b36c05b92'/>
                    </div>
                    <div className="grid-item mr-5 flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(30%20of%20150).jpg?alt=media&token=46959eba-96fa-40b1-868e-d165295359cc'/>
                    </div>
                    <div className="grid-item flex-20">
                        <img alt='img-one' onClick={(evt) => handleClick(evt)} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FGallery%2FAmenities%2FAnoud%20Manor%20(27%20of%20150).jpg?alt=media&token=5393c98e-ec14-44d4-8203-912a3582ea8d'/>
                    </div>
                </div>
            </div>}
            </div>
            </CSSTransition>
        </div>
    )
}

export default Gallery

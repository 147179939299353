import React from 'react'
import './RoomTwo'

// Components
import RoomGallery from './RoomGallery';

// Icons
import HotelIcon from '@material-ui/icons/Hotel';
import BathtubIcon from '@material-ui/icons/Bathtub';
import KitchenIcon from '@material-ui/icons/Kitchen';
import WifiIcon from '@material-ui/icons/Wifi';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';


function RoomTwo() {

    const images = [
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(136%20of%20150).jpg?alt=media&token=889f8145-0a96-4622-a3c7-890094c4cbd0',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(136%20of%20150).jpg?alt=media&token=889f8145-0a96-4622-a3c7-890094c4cbd0',
            description: 'Room One'
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(140%20of%20150).jpg?alt=media&token=e499ca98-a492-4a31-b945-cdb85bdbce20',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(140%20of%20150).jpg?alt=media&token=e499ca98-a492-4a31-b945-cdb85bdbce20',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(142%20of%20150).jpg?alt=media&token=98425ab9-1737-4735-b841-8a474c167a16',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(142%20of%20150).jpg?alt=media&token=98425ab9-1737-4735-b841-8a474c167a16',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(144%20of%20150).jpg?alt=media&token=b30ea60d-199c-428e-8b09-d0333980c097',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(144%20of%20150).jpg?alt=media&token=b30ea60d-199c-428e-8b09-d0333980c097',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(81%20of%20150).jpg?alt=media&token=94bb41e0-0a72-4338-ae46-fe4c9148de0f',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(81%20of%20150).jpg?alt=media&token=94bb41e0-0a72-4338-ae46-fe4c9148de0f',
        },
        {
            original: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(85%20of%20150).jpg?alt=media&token=e93a7b76-9072-410b-85ae-6227e0f9873b',
            thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Images%2FRooms%2FNoor%2FAnoud%20Manor%20(85%20of%20150).jpg?alt=media&token=e93a7b76-9072-410b-85ae-6227e0f9873b',
        },
        

];

    return (
<div className='main-room'>
           <div className="main-room-left">
                <div className="main-room-image-container">
                    {/* <img className='main-room-image' src='https://cdn.decoist.com/wp-content/uploads/2018/07/Exterior-of-the-lavish-Manor-House-in-Hampshire.jpg' alt='main-room' /> */}
                    <RoomGallery imageArray={images} />
                </div>
           </div>
           <div className="main-room-right">
           <h1>Noor Cottage - R 3990 / night</h1> 
            <p>The Noor Cottage offers our best combination of space and privacy. With two bedrooms each hosting their own en suite bathrooms, the cottage if perfect for just about anyone. Wether you're travelling in couples, with children or as two people travelling together, the Noor Cottage caters for you. The open plan kitchen adjoins the lounge and dining areas which, in turn, open up onto the pation section alongside the pool. Having the sauna and spa right next door to your cottage is a plus that can't be overlooked and means that you're never far away from complete relaxation during your stay at Anoud Manor.</p>
            <br />
            {/* <p>And also the following:</p> */}
            <br />
            <div className="icon-group">
                <HotelIcon /> <p>2 Bedrooms</p>
            </div>
            <div className="icon-group">
                <BathtubIcon /> <p>2 Bathrooms</p>
            </div>
            <div className="icon-group">
                <KitchenIcon /> <p>Private Kitchen</p>
            </div>
            <div className="icon-group">
                <WifiIcon /> <p>Free Wifi</p>
            </div>
            <div className="icon-group">
                <EmojiFoodBeverageIcon /><p>Private Lounge</p>
            </div>
            <br></br>
           </div>
        </div>
    )
}

export default RoomTwo

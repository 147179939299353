import React, { useState } from 'react'
import './Header.css'

// Material UI
import MenuIcon from '@material-ui/icons/Menu';
import { Menu } from '@material-ui/core';

// Components
import DropDownMenu from './DropDownMenu';

// Router
import { NavLink, useHistory } from 'react-router-dom'

function Header() {

    const history = useHistory();

    // State
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="header-container">

        <div className='header'>
            <div className="header-left">
                <img onClick={() => history.push('/')} src='https://firebasestorage.googleapis.com/v0/b/hout-bay-manor-house.appspot.com/o/Untitled-1.png?alt=media&token=fb484ebe-9d8c-47e3-ad8e-8818525679b3' alt='headerimg' />
            </div>
            <div className="header-middle">
                <NavLink activeClassName='header-navlink-active' className='header-navlink' exact to='/'>Home</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink' to='/accomodation'>Accomodation</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink' to='/about'>About</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink' to='/gallery'>Gallery</NavLink>
                <NavLink activeClassName='header-navlink-active' className='header-navlink' to='/contact'>Contact</NavLink>
            </div>
            <div onClick={() => setMenuOpen(!menuOpen)} className="header-right">
                <MenuIcon />
            </div>
        </div>
        {menuOpen && <DropDownMenu closeMenu={() => setMenuOpen(false)} />}
        </div>

    )
}

export default Header

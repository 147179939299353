import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';


// Components
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer'

// Pages
import Home from './Pages/Home'
import Rooms from './Pages/Rooms/Rooms'
import Contact from './Pages/Contact/Contact'
import Gallery from './Pages/Gallery/Gallery'
import About from './Pages/About/About'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
          <Switch>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/gallery'>
              <Gallery />
            </Route>
            <Route path='/accomodation'>
              <Rooms />
            </Route>
            <Route path='/contact'>
              <Contact />
            </Route>
            <Route exact path='/'>
              <Home />
            </Route>
          </Switch>
          <Footer /> 
      </Router>
    </div>
  );
}

export default App;

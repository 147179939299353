import React from 'react'
import './NearbyBox.css'

function NearbyBox({ imageLink, venueName, details, link}) {
    return (
        <div className='nearby-box'>
                        <div className="nearby-box-image-container">
            <img alt='room-img' src={imageLink} />
            </div>
            <div className="nearby-box-content">
            <h3>{venueName}</h3>
            <p>{details}</p>
            <div className="nearby-box-button-row">
            <button onClick={() => window.open(link)} >Directions</button>
            </div>

            </div>
        </div>
    )
}

export default NearbyBox
